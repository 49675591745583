<template>
  <div class="w-100">
    <div class="text-center associated-selection" :class="{'mb-4': device==='mobile', 'w-50 mx-auto': device==='desktop'}">
      <!-- :dropdownShouldOpen="() => true" -->
      <v-select v-model="selectedDate" :options="productsListOptions" :searchable="false" dir="rtl" :selectable="optionSelectable" :clearable="false">
        <template #selected-option="{ startLabel, endLabel, duration }">
          <div class="pr-1" style="position: relative; display: flex;">
            <div class="col-md-9 col-9 p-0">
              <span style="text-wrap: nowrap;">
                <!-- <span class="associated-selection-label align-self-center">{{ $t('product-page.choose-dates') }}</span><br/> -->
                <span class="selected-duration">{{ startLabel }} - {{ endLabel }}, {{ duration }} {{ $t('search-result.night') }}</span><br/>
                <!-- <span class="selected-price">{{ priceString }}</span> -->
              </span>
            </div>
            <div class="col-md-3 col-3 align-self-center p-0">
              <b-button class="selected-button w-100">{{ $t('product-page.change-date') }}</b-button>
            </div>
          </div>
        </template>

        <!-- <template #list-header>
          <div class="option row associated-selection-header">
            <span style="width: 29%;" class="text-center"> {{ $t('booking.departure-date') }}</span>
            <span style="width: 29%;" class="text-center">{{ $t('booking.arrival-date') }}</span>
            <span style="width: 12%;" class="text-center">{{ $t('booking.no-nights') }}</span>
            <span style="width: 15%;" class="text-center" v-if="adult===2 && child===0">{{ $t('booking.average-price-per-one-double') }}</span>
            <span style="width: 15%;" class="text-center" v-else-if="adult > 0">{{ $t('booking.average-price-per-one-regular', {adult, child}) }}</span>
            <span style="width: 15%;" class="text-center"></span>
          </div>
        </template> -->

        <template v-slot:option="{ datesString, duration, disabled, priceString }">
          <div class="option row associated-selection-option" :class="disabled ? 'thin-line' : ''">
            <span class="col-md-4 col-4"><span class="associated-option-item">{{ datesString }}</span></span>
            <span class="col-md-2 col-2"><span class="associated-option-item">{{ duration }} {{ $t('search-result.night') }}</span></span>
            <span class="col-md-3 col-3">
              <span class="associated-option-item price">
                <span v-if="category!=='Flight_Only'" class="p-0">{{ $t('product-page.from')}}-</span>{{ priceString }}
              </span>
            </span>
            <span class="col-md-3 col-3 align-self-center"><b-button class="selected-button">{{ $t('product-page.book-button') }}</b-button></span>
          </div>
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';

export default {
  name: 'associated-products-list',
  props: {
    productsList: {
      type: Array,
      default: () => [],
    },
    category: {
      type: String,
      default: '',
    },
  },
  components: {
    vSelect,
  },
  data() {
    return {
      selectedDate: null,
      initialValue: {
        startLabel: '0000-00-00 00:00',
        endLabel: '0000-00-00 00:00',
        duration: 0,
        value: -1,
        label: '',
        productLink: '',
        disabled: true,
      },
    };
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
      isNotFirstEnter: 'GET_CHANGED_DATE_STATE_FOR_MACCABI_AGENCY',
    }),
    adult() {
      return +this.$route.query.adult;
    },
    child() {
      return +this.$route.query.child;
    },
    productsListOptions() {
      const { productsList } = this;

      return productsList.map((product, inx) => {
        const startDate = product.packStartDate;
        // const startTime = '';
        const startWeekName = this.weekDaysNames[dayjs(startDate).day()];
        const endDate = product.packEndDate;
        // const endTime = '';
        const endWeekName = this.weekDaysNames[dayjs(endDate).day()];

        const diff = dayjs(endDate).diff(startDate, 'day');

        return {
          startLabel: `${startWeekName} ${dayjs(startDate).format('DD/MM/YY')}`,
          endLabel: `${endWeekName} ${dayjs(endDate).format('DD/MM/YY')}`,
          duration: diff,
          productLink: product.productPageUrl,
          value: inx,
          label: '',
          adult: product.adults,
          child: product.children,
          priceString: `${product.currenySymbol}${product.avgPrice.toFixed(0)}`,
          disabled: false,
          datesString: `${dayjs(endDate).format('DD.MM.YY')} - ${dayjs(startDate).format('DD.MM.YY')}`,
        };
      });
    },
    weekDaysNames() {
      return [
        this.$t('weekName.sun'),
        this.$t('weekName.mon'),
        this.$t('weekName.tue'),
        this.$t('weekName.wed'),
        this.$t('weekName.thu'),
        this.$t('weekName.fri'),
        this.$t('weekName.sat'),
      ];
    },
  },
  watch: {
    selectedDate() {
      this.refreshWithNewProduct();
    },
  },
  mounted() {
    this.getCurrentProduct();
  },
  methods: {
    getProductLinkParamObj(product) {
      return Object.fromEntries(new URLSearchParams(product.productLink.substr(product.productLink.indexOf('?'), product.productLink.length)));
    },
    getCurrentProduct() {
      const { productsListOptions } = this;
      const { query } = this.$route;
      this.selectedDate = productsListOptions.find(
        (product) => this.compareObjects(query, this.getProductLinkParamObj(product)),
      );
      if (!this.selectedDate) {
        this.selectedDate = productsListOptions[0];
      }
    },
    compareObjects(obj1, obj2) {
      const keys1 = Object.keys(obj1);
      // const keys2 = Object.keys(obj2);

      // if (keys1.length !== keys2.length) {
      //   return false;
      // }

      // eslint-disable-next-line no-restricted-syntax
      for (const key of keys1) {
        if (key !== 'channel' && key !== 'associated' && obj1[key] && obj2[key] && obj1[key] !== obj2[key]) {
          return false;
        }
      }

      return true;
    },
    refreshWithNewProduct() {
      const { selectedDate } = this;
      const { query } = this.$route;
      if (!selectedDate) return false;
      if (this.compareObjects(query, this.getProductLinkParamObj(selectedDate))) return true;

      this.$router.push({ path: selectedDate.productLink });
      this.$emit('update');
      this.$store.commit('SET_CHANGED_DATE_FOR_MACCABI_AGENCY_PAGE', true);
      return true;
    },
    optionSelectable(option) {
      return !option.disabled;
    },
  },
};
</script>

<style>
  #did-you-know {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
  .maccabi-remark {
    font-size: 1.2rem;
  }
  .associated-selection {
    border-radius: 10px;
    border: 2px solid #4CC5F3;
  }
  .associated-selection .vs__dropdown-toggle{
    background: transparent;
    padding: 3px 0;
  }
  .associated-selection .vs__selected{
    width: 100%;
    text-align: right;
  }
  .associated-selection .vs__selected .selected-price{
    color: #0160AB;
  }
  .associated-selection .vs__actions{
    display: none;
  }
  .associated-selection .vs__selected .selected-button,
  .associated-selection .associated-selection-option .selected-button{
    background-color: #3493CD;
    border-radius: 30px;
    border: none;
  }
  .associated-selection .associated-selection-option .selected-button{
    box-shadow: gray 2px 2px 10px;
  }
  .associated-selection .vs__selected .selected-price,
  .associated-selection .vs__selected .selected-button,
  .associated-selection .associated-selection-option .selected-button{
    font-size: 1.3rem;
    line-height: 1.4rem;
    font-weight: bold;
  }
  .associated-selection .vs__selected .selected-duration{
    font-size: 1.3rem;
  }
  .associated-selection .v-select .vs__dropdown-menu {
    overflow-x: hidden;
    padding-bottom: 0;
    padding-top: 0;
    margin-top: 0.5rem;
  }
  .associated-selection .v-select .vs__dropdown-menu .associated-option-item {
    display: block;
    border-radius: 5px;
    background-color: #0160AB;
    color: white;
    width: 100%;
    font-size: 1.2rem;
    line-height: 1.1rem;
  }
  .associated-selection .v-select .vs__dropdown-menu .associated-option-item.price {
    font-weight: bold;
  }

  .associated-selection .vs__selected-options{
    height: 2.3rem;
  }
  .associated-selection .vs__dropdown-option {
    padding: 0;
    /* border-top: solid 1px gray; */
    text-align: center;
  }
  /* .associated-selection .vs__dropdown-option:last-child {
    border-bottom: solid 1px gray;
  }
  .associated-selection.w-75 .vs--open {
    width: 75%;
  }
  .associated-selection-label {
    font-size: 1.2rem;
    font-weight: 500;
  } */
  .associated-selection .vs__dropdown-option--highlight {
    background-color: #e2ecfb;
  }

  /* .associated-selection-header {
    margin: 0;
    font-weight: 600;
  }
  .associated-selection-header span:not(:last-child){
    border-left: solid 1px gray;
  }
  .associated-selection-header span{
    background-color: rgb(197, 222, 243);
    align-items: center;
    display: inline-grid;
    line-height: 1rem;
  } */

  .associated-selection-option {
    padding: 0;
    margin: 0;
  }
  /* .associated-selection-option span:not(:last-child){
    border-left: solid 1px gray;
  } */
  .associated-selection-option span{
    /* align-items: flex-start; */
    padding: 0.5rem;
    margin: 0;
  }

  .associated-selection .vs--single .vs__selected {
    display: block;
    margin: auto;
  }
  .associated-selection .vs__search {
    flex-grow: unset;
  }
  .associated-selection .vs--single.vs--open .vs__selected {
    position: inherit;
  }
  /* .empoyee-subsidy-message {
    font-size: 2rem;
    font-weight: bold;
    color: purple;
  } */
  .thin-line::after {
    content: '';
    transform: translateY(-20px);
    border-top: 1px solid gray;
    width: 78%;
    margin-right: 25px;
  }
@media (max-width: 479px) {
  /* .empoyee-subsidy-message {
    font-size: 1.5rem;
  } */
  .associated-selection .vs__selected .selected-button{
    font-size: 1rem;
    line-height: 1rem;
  }
  .associated-selection .vs__selected .selected-button {
    padding-right: 9px;
    padding-left: 9px;
  }
  .associated-selection .vs__selected .selected-duration{
    font-size: 0.95rem;
  }
  .associated-selection-option>span{
    padding: 0.1rem;
  }
  .associated-selection .vs--single .vs__selected {
    padding: 0;
  }
  .associated-selection .v-select .vs__dropdown-menu .associated-option-item,
  .associated-selection .associated-selection-option .selected-button {
    font-size: 0.8rem;
    line-height: 0.8rem;
  }
  .thin-line::after {
    width: 85%;
    margin-right: 5px;
  }
}
</style>
